/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

------------------------------------------------------------- */
.loginArea {
  position: relative;
  box-sizing: border-box;
  display: grid; }
  .loginArea .loginCard {
    vertical-align: middle;
    background: #fff;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    color: #36464E;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -khtml-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    width: 480px;
    height: 491px;
    margin: auto;
    text-align: center; }
  .loginArea .loginView {
    width: 350px;
    padding: 20px;
    margin: auto; }
    .loginArea .loginView .text {
      font-size: 24px;
      margin: 25px auto;
      color: #253137;
      font-weight: 500; }
    .loginArea .loginView .rememberArea {
      display: block;
      height: 36px;
      width: 100%;
      text-align: left;
      margin: 10px auto;
      font-size: 14px; }
      .loginArea .loginView .rememberArea .rememberMe {
        display: inline-block;
        vertical-align: top;
        color: #778f9b; }
      .loginArea .loginView .rememberArea .forgetLink {
        float: right;
        vertical-align: top;
        color: #f69f21; }
    .loginArea .loginView .loginButton {
      text-align: center;
      padding: 10px; }
      .loginArea .loginView .loginButton button {
        width: 180px;
        background: #f69f21; }
  .loginArea .signUpView {
    width: 100%;
    height: 120px;
    background: #f9f9f9;
    padding: 14px;
    box-sizing: border-box;
    text-align: center;
    -webkit-border-radius: 0 0 3px 3px;
    -khtml-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -ms-border-radius: 0 0 3px 3px;
    -o-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
    border-top: 1px solid #ced7db; }
    .loginArea .signUpView .text {
      font-size: 14px;
      margin: 15px; }
    .loginArea .signUpView .signupButton {
      width: 180px;
      color: #f69f21;
      background: #fff;
      -webkit-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
      -khtml-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
      -moz-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
      -ms-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
      -o-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
      box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07); }

.verionNumber {
  text-align: center;
  font-size: 14px;
  position: absolute;
  bottom: 10px;
  color: #5b666c;
  left: calc(50% - 24px); }

.exceederLoading {
  width: 128px;
  height: 128px;
  position: relative;
  margin: 150px auto;
  border: 1px solid red;
  animation: moveExceeder 1.5s linear infinite; }
  .exceederLoading .x1, .exceederLoading .x2, .exceederLoading .x3, .exceederLoading .x4 {
    width: 64px;
    height: 10px;
    position: absolute;
    transform: rotate(50deg); }
  .exceederLoading .x1 {
    width: 42px;
    top: 54px;
    left: 17px; }
    .exceederLoading .x1 div {
      border-radius: 15px;
      width: 6px;
      height: 6px;
      margin: 1px;
      background: #62a60a;
      display: none;
      box-sizing: border-box;
      position: relative; }
      .exceederLoading .x1 div:before {
        width: 6px;
        height: 6px;
        position: absolute;
        top: -8px;
        content: '';
        background: #62a60a;
        border-radius: 15px; }
      .exceederLoading .x1 div:after {
        width: 6px;
        height: 6px;
        position: absolute;
        bottom: -8px;
        content: '';
        background: #62a60a;
        border-radius: 15px; }
      .exceederLoading .x1 div:first-child {
        background: transparent; }
        .exceederLoading .x1 div:first-child:before {
          background: transparent; }
      .exceederLoading .x1 div:nth-child(3):before {
        top: -16px; }
  .exceederLoading .x2 {
    transform: rotate(135deg);
    width: 104px;
    top: 58px;
    right: -12px; }
    .exceederLoading .x2 div {
      border-radius: 15px;
      width: 6px;
      height: 6px;
      margin: 1px;
      background: #62a60a;
      display: none;
      box-sizing: border-box;
      position: relative; }
      .exceederLoading .x2 div:before {
        width: 6px;
        height: 6px;
        position: absolute;
        top: -8px;
        content: '';
        background: #62a60a;
        border-radius: 15px; }
      .exceederLoading .x2 div:after {
        width: 6px;
        height: 6px;
        position: absolute;
        bottom: -8px;
        content: '';
        background: #62a60a;
        border-radius: 15px; }
      .exceederLoading .x2 div:first-child {
        background: transparent; }
        .exceederLoading .x2 div:first-child:before {
          background: transparent; }
      .exceederLoading .x2 div:nth-child(3):before {
        top: -16px; }
      .exceederLoading .x2 div:nth-child(5):after {
        bottom: -16px; }
      .exceederLoading .x2 div:nth-child(7):before {
        background: transparent; }
  .exceederLoading .x3 {
    background: #999;
    transform: rotate(135deg);
    width: 30px;
    bottom: 8px;
    left: 20px;
    height: 23px; }
    .exceederLoading .x3::after {
      right: -23px;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 23px 23px 23px;
      border-color: transparent transparent #999 transparent; }
  .exceederLoading .x4 {
    background: #999;
    transform: rotate(45deg);
    width: 30px;
    bottom: 8px;
    right: 38px;
    height: 23px; }
    .exceederLoading .x4::after {
      right: -23px;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 23px 23px 23px 23px;
      border-color: #999 transparent transparent transparent; }

@-webkit-keyframes moveExceeder {
  0% {
    .x1 div:nth-child(-n+2) {
      display: inline-block; } }
  50% {
    .x1 div:nth-child(-n+5) {
      display: inline-block; } }
  100% {
    .x1 div:nth-child(-n+7) {
      display: inline-block; } } }

@-moz-keyframes moveExceeder {
  0% {
    .x1 div:nth-child(-n+2) {
      display: inline-block; } }
  50% {
    .x1 div:nth-child(-n+5) {
      display: inline-block; } }
  100% {
    .x1 div:nth-child(-n+7) {
      display: inline-block; } } }

@-ms-keyframes moveExceeder {
  0% .x1 div:nth-child(-n+2) {
    display: inline-block; }
  50% .x1 div:nth-child(-n+5) {
    display: inline-block; }
  100% .x1 div:nth-child(-n+7) {
    display: inline-block; } }

@-o-keyframes moveExceeder {
  0% {
    .x1 div:nth-child(-n+2) {
      display: inline-block; } }
  50% {
    .x1 div:nth-child(-n+5) {
      display: inline-block; } }
  100% {
    .x1 div:nth-child(-n+7) {
      display: inline-block; } } }

@keyframes moveExceeder {
  0% {
    .x1 div:nth-child(-n+2) {
      display: inline-block; } }
  50% {
    .x1 div:nth-child(-n+5) {
      display: inline-block; } }
  100% {
    .x1 div:nth-child(-n+7) {
      display: inline-block; } } }
