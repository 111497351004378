/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

------------------------------------------------------------- */
.passwordArea {
  position: relative;
  box-sizing: border-box;
  display: grid; }
  .passwordArea .passwordCard {
    vertical-align: middle;
    background: #fff;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    color: #36464E;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -khtml-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    width: 480px;
    height: 491px;
    margin: auto;
    text-align: center; }
    .passwordArea .passwordCard.step0 {
      height: 436px; }
    .passwordArea .passwordCard.step1 {
      height: 431px; }
    .passwordArea .passwordCard.step2 {
      height: 456px; }
  .passwordArea .passwordView {
    width: 350px;
    padding: 20px;
    margin: auto; }
    .passwordArea .passwordView .text {
      font-size: 24px;
      margin: 25px auto;
      color: #253137;
      font-weight: 500; }
    .passwordArea .passwordView .rememberArea {
      display: block;
      height: 36px;
      width: 100%;
      text-align: left;
      margin: 10px auto;
      font-size: 14px; }
      .passwordArea .passwordView .rememberArea .rememberMe {
        display: inline-block;
        vertical-align: top;
        color: #778f9b; }
      .passwordArea .passwordView .rememberArea .forgetLink, .passwordArea .passwordView .rememberArea .resendLink {
        float: right;
        vertical-align: top;
        color: #f69f21; }
      .passwordArea .passwordView .rememberArea .resendLink {
        cursor: pointer; }
        .passwordArea .passwordView .rememberArea .resendLink.disabled {
          cursor: default;
          color: #afbdc4; }
    .passwordArea .passwordView .loginButton {
      text-align: center;
      padding: 10px; }
      .passwordArea .passwordView .loginButton button {
        width: 180px;
        background: #f69f21; }
    .passwordArea .passwordView .stepButton {
      text-align: center;
      padding: 10px;
      margin-top: 20px; }
      .passwordArea .passwordView .stepButton button {
        min-width: 180px;
        background: #f69f21; }
  .passwordArea .signUpView {
    width: 100%;
    height: 120px;
    background: #f9f9f9;
    padding: 14px;
    box-sizing: border-box;
    text-align: center;
    -webkit-border-radius: 0 0 3px 3px;
    -khtml-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -ms-border-radius: 0 0 3px 3px;
    -o-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
    border-top: 1px solid #ced7db; }
    .passwordArea .signUpView .text {
      font-size: 14px;
      margin: 15px; }
    .passwordArea .signUpView .signupButton {
      width: 180px;
      color: #f69f21;
      background: #fff;
      -webkit-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
      -khtml-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
      -moz-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
      -ms-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
      -o-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
      box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07); }
