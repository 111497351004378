@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900|Maven+Pro:400,500,700,900");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "./_flaticon.scss";
@import './css3-mixins.scss';
@import './variables.scss';
@import './icons.scss';

body{width:100%;margin:0px;padding:0;height:100%;position:relative;overflow:auto;font-family: 'Maven Pro';background:#F7F9F9;}

a, a:visited, a:active{text-decoration:none;color:$primaryColor;cursor:pointer;}
a:hover{text-decoration:none;}

.relative{position:relative;}
.absolute{position:absolute;}
.fixed{position:fixed;}

.di{display:inline;}
.dib{display:inline-block;}
.db{display:block;}
.dt{display:table;}
.dno{display:none;}

.vt{vertical-align:top;}
.vb{vertical-align:bottom;}

.f11{font-size:11px;}
.f12{font-size:12px;}
.f13{font-size:13px;}
.f14{font-size:14px;}
.f16{font-size:16px;}
.f18{font-size:18px;}
.f22{font-size:22px;}
.f24{font-size:24px;}
.f28{font-size:28px;}
.f32{font-size:32px;}
.f36{font-size:36px;}
.f42{font-size:42px;}
.f48{font-size:48px;}
.f54{font-size:54px;}

.bold{font-weight:bold;}
.normal{font-weight:normal;}
.meduim{font-weight:500;}
.italic{font-style:italic;}

.text-left{text-align:left;}
.text-center{text-align:center;}
.text-right{text-align:right;}
.text-capitalize{text-transform:capitalize !important;}
.text-uppercase{text-transform:uppercase !important;}
.text-lowercase{text-transform:lowercase !important;}


.pointer,.outsideclick{cursor:pointer;}
.ct{cursor:text;}
.cd{cursor:default;}
.cm{cursor:move !important;}

.right{float:right;}
.left{float:left;}

.fullWidth{width:100%;}
.fullHeight{height:100%;}
.overflow{overflow:auto;}

.nopad{padding:0;}
.pad2{padding:2px;}
.pad3{padding:3px;}
.pad5{padding:5px;}
.pad7{padding:7px;}
.pad10{padding:10px;}
.pad15{padding:15px;}
.pad20{padding:20px;}
.pad40{padding:40px;}
.pad50{padding:50px;}
.padt0{padding-top:0px;}
.padt5{padding-top: 5px;}
.padt7{padding-top:7px;}
.padt10{padding-top:10px;}
.padt20{padding-top:20px;}
.padt40{padding-top:40px;}
.padt50{padding-top:50px;}
.padb0{padding-bottom:0px;}
.padb7{padding-bottom:7px;}
.padb10{padding-bottom:10px;}
.padb20{padding-bottom:20px;}
.padb40{padding-bottom:40px;}
.padb50{padding-bottom:50px;}
.padl0{padding-left:0px;}
.padl10{padding-left:10px;}
.padl15{padding-left:15px;}
.padl20{padding-left:20px;}
.padl40{padding-left:40px;}
.padl50{padding-left:50px;}
.padr0{padding-right:0px;}
.padr10{padding-right:10px;}
.padr20{padding-right:20px;}
.padr40{padding-right:40px;}
.padr50{padding-right:50px;}

.marauto{margin:auto;}
.marcenter{margin:0 auto;}
.nomar{margin:0;}
.mar2{margin:2px;}
.mar3{margin:3px;}
.mar5{margin:5px;}
.mar7{margin:7px;}
.mar10{margin:10px;}
.mar40{margin:40px;}
.mar50{margin:50px;}
.mart0{margin-top:0px;}
.mart5{margin-top:5px;}
.mart10{margin-top:10px;}
.mart15{margin-top:15px;}
.mart20{margin-top:20px;}
.mart30{margin-top:30px;}
.mart40{margin-top:40px;}
.mart50{margin-top:50px;}
.marb0{margin-bottom:0px;}
.marb10{margin-bottom:10px;}
.marb20{margin-bottom:20px;}
.marb40{margin-bottom:40px;}
.marb50{margin-bottom:50px;}
.marl0{margin-left:0px;}
.marl5{margin-left:5px;}
.marl10{margin-left:10px;}
.marl20{margin-left:20px;}
.marl30{margin-left:30px;}
.marl40{margin-left:40px;}
.marl50{margin-left:50px;}
.marr0{margin-right:0px;}
.marr10{margin-right:10px;}
.marr20{margin-right:20px;}
.marr40{margin-right:40px;}
.marr50{margin-right:50px;}
.mart-5{margin-top:-5px;}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}


::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: rgba(246, 159, 33, 0.6);
  border: 5px none #ffffff;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: #f69f21;
}
::-webkit-scrollbar-thumb:active {
  background: #f69f21;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 3px;
}
::-webkit-scrollbar-track:hover {
  background: #f1f1f1;
}
::-webkit-scrollbar-track:active {
  background: #f1f1f1;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.showbox{position:relative;}
.centered{position:relative;display:table;width:100%;height:100%;
  .showbox{text-align:center;display:table-cell;vertical-align:middle;}
}
.text-green{color:#93c74b}
.loader{position:relative;margin:0 auto;width:50px;}
.loader.small{width:36px;}
.loader:before{content:"";display:block;padding-top:100%}
.circular{animation:rotate 2s linear infinite;height:100%;transform-origin:center center;width:100%;position:absolute;top:0;bottom:0;left:0;right:0;margin:auto}
.path{stroke-dasharray:1,200;stroke-dashoffset:0;animation:dash 1.5s ease-in-out infinite,color 6s ease-in-out infinite;stroke-linecap:round}
@keyframes rotate{to{transform:rotate(1turn)}}
@keyframes dash{0%{stroke-dasharray:1,200;stroke-dashoffset:0}50%{stroke-dasharray:89,200;stroke-dashoffset:-35px}to{stroke-dasharray:89,200;stroke-dashoffset:-124px}}
@keyframes color{0%,to{stroke:#d62d20}40%{stroke:#0057e7}66%{stroke:#008744}80%,90%{stroke:#ffa700}}

.smallCardList{box-sizing: border-box;
  & > li{@include box-shadow(0, 1px, 2px, rgba(0,0,0,.2));display:block;padding:12px 15px 10px;margin:10px 0;@include border-radius(3px);position: relative;
    .listImage{width: 44px;height: 44px;margin-right: 10px;border-radius: 100%;text-align: center;box-sizing: border-box;position: relative;
      > img{width:26px;height:26px;top: 50%;transform: perspective(1px) translateY(-50%);position: relative;}
    }
    .listText{cursor: pointer;width: calc(100% - 250px);height: calc(100% - 25px);
      &.singleLine{padding-top:12px;box-sizing: border-box;}
    }
    .listValue{width: 250px;margin-top:5px;
      .lv-item{width: 128px;}
    }
    .value{color: #253137;font-size: 16px;}
    .title{color: #8fa3ad;font-size: 11px;}
    .listOption{width:36px;text-align: right;margin-top:4px;}
  }
}
.noListContainer{width:100%;height:100%;padding:20px;position: relative;box-sizing:border-box;display:table;
	.noList{text-align:center;display: table-cell;vertical-align: middle;
		.nl-icon{margin:25px auto;
			img{width:164px;height:164px;}
		}
		.nl-header{font-size:32px;color:#37474F;font-weight: 300;margin:10px auto;}
		.nl-des{font-size:14px;color:#78909C;font-weight: 300;white-space: normal;}
		.nl-button{font-size:14px;font-weight: 400;margin-top:20px;}
	}
}
.drawerWrapper{width:300px;border-right:none !important;background:#fff;height:100%;
	.topContainer{background:#f69f21;height:128px;color:#fff;margin-bottom:28px;position: relative;
    .drawer{width:60px;height:50px;
      .drawerIcon{font-size:28px;color:#fff;cursor: pointer;margin:18px;}
		}
    .headerOptions{width:60px;height:50px;float:right;margin-top:12px;
      button{color:#fff;}
		}
		.displayName{display:block;padding:10px 18px 0px;font-size:18px;min-height:25px;}
		.displayEmail{display:block;padding:0 18px 5px;font-size:12px;color:rgba(255,255,255,0.7);text-transform:lowercase;}
		.displayImage{display:block;padding:0 18px;
			img{width:50px;height:50px;@include border-radius(100%);background:#ced7db;}
		}
		.syncProfile{display: block;position: absolute;right: 20px;font-size: 24px;border-radius: 100%;height: 24px;width: 24px;text-align: center;top: 20px;cursor: pointer;}
	}
	.menu{list-style:none;height:calc(100% - 240px);position: relative;overflow: auto;width: 100%;
		li{padding:0;text-transform:uppercase;cursor:pointer;font-size:12px;
			a{color:#37474F;text-decoration:none;padding:14px 16px;display:block}
			a:hover{color:#37474F;text-decoration:none;background:#ECEFF1;}
			.text{display:inline-block;margin-top:4px;font-size:14px;font-weight: 500;color: #36464e;}
			.icon{display:inline-block;margin:0 12px 0 0;vertical-align: top;
				.menuNavIcon{width:24px;height:24px;cursor:pointer;background:url('../images/icons240.png');background-position:-48px 0px;
					&.lead{background-position:-48px 0px;}
					&.user{background-position:-96px -2px;}
					&.product{background-position:-120px -2px;}
				}
			}
			.badge{display:inline-block;margin:3px;vertical-align: top;float:right;background: #FBB150;padding: 3px 7px;border-radius:10px;color: #fff;font-size: 11px;}
		}
		
		@include respond-to(mobile) {height:auto;}
		@include respond-to(tablet) {height:auto;}
	}
	.bottomMenu{list-style:none;border-top:1px solid #ECEFF1;position:absolute;bottom:0;left:0;width:100%;
		li{padding:18px;text-transform:uppercase;cursor:pointer;font-size:12px;color:#37474F;
			&:hover{background:#ECEFF1;}
			.text{display:inline-block;margin-top:4px;font-size:14px;font-weight: 500;color: #36464e;}
			.icon{display:inline-block;margin:0 12px 0 0;vertical-align: top;
				.menuNavIcon{width:24px;height:24px;cursor:pointer;background:url('../images/icons240.png');background-position:-72px 0px;
				}
			}
		}
		@include respond-to(tablet) {position:relative;}
		@include respond-to(mobile) {position:relative;}
	}
}
.detailPage{
	position:fixed;width:100vw;height:100vh;top:0;left:0;margin:0;background:#ebeef0;z-index: 2000;
	.header{background:#fff;width:100vw;height:60px;box-shadow: 0 5px 5px -5px rgba(0,0,0,.15);position: absolute;z-index: 10;
    .back{width:48px;height:60px;box-sizing:border-box;padding:14px 0px;text-align:center;cursor:pointer;margin-right:10px;
      .icon{font-size:30px;color:#546e7a;}
    }
		.headerWrapper{width:calc(100% - 70px);height:60px;box-sizing:border-box;
			.logo{display:inline-block;vertical-align:top;margin:8px 0;
				img{width:42px;height:42px;@include border-radius(100%);}
			}
			.text{display:inline-block;vertical-align:top;font-size:16px;margin:20px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
		}
	}
	.body{background:#f9fafb;height:calc(100% - 60px);overflow-y:auto;display:table;padding-left:15px;width:calc(100vw - 15px);margin-top:60px;@include box-shadow(0px, 0, 0, rgba(0,0,0,.15), 'inset');@include css3-prefix('height', calc(100% - 60px));
		.leftSide{display:table-cell;vertical-align:top;height:100%;background:#fff;position:relative;@include css3-prefix('box-shadow', '5px 0 5px -5px rgba(0,0,0,0.2), -5px 0 5px -5px rgba(0,0,0,0.2)');width:340px;
			.topLeftArea{padding:20px 15px 0px;max-height: 250px;overflow-y: auto;}
			.bottomRow{bottom:50px;position:absolute;width:100%;background: #fff;}
			//.middleRow{background:#f9fafc;box-sizing:border-box;padding:15px 0;background-image:url('../images/detail_middle_background.png');background-size: cover;min-height:55px;}
		}
		.rightSide{display:table-cell;vertical-align:top;height:100%;width:calc(100% - 300px);}
		.verticalTabList{width:100%;display:block;margin-top: 5px;
			li{display:block;box-sizing:border-box;font-size:14px;cursor:pointer;padding:0 15px;
				.listItem{padding:15px;border-bottom:1px solid #e6e6e6;transition:border 0.5s ease-in;
					.icon{display:inline-block;vertical-align:top;margin-right:10px;color:#78909c;}
					.text{display:inline-block;vertical-align:top;color:#253137;}
					.number{display:inline-block;vertical-align:top;float:right;color:#778F9B;
						&.leadStageCode{padding:5px 10px;border-radius: 20px;color: #fff;font-size:11px;margin-top: -3px;}
					}

				}
				&.disabled{cursor:default;
					.icon {color:#8fa3ad;}
					.text {color:#8fa3ad;}
					.number{color:#8fa3ad;}
					&:hover{background-color:transparent;}
				}
				&.information .text{color:#253137;}
				&:hover:not(.disabled),&.active:not(.disabled){
					.listItem{border-color:#f59e20;}
				}
				/*&:last-child .listItem{border-bottom:none;}*/
			}
		}
	}
}
.floatingMenu{bottom:0;right: 0;position: fixed;margin:0px;
	.mainButton{margin:20px;color:#fff !important;background:#f69f21 !important;box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2) !important;
		span{transform:rotate(0deg);}
	}
	.childrens{bottom:88px;right:0px;position: fixed;margin:0px;width:180px;margin-right: -74px;text-align:center;display:none;@include transition(all 500ms cubic-bezier(0.4, 0, 1, 1));
		.childDiv{height:40px;width: 40px;min-width:auto;text-align:center;margin:auto;margin-left: 36px;
			.tooltip{background-color:#536D79;color:#fff;position:absolute;left:0;width:auto;transform-origin:center top;font-size:10px;height:22px;line-height:22px;padding-right:8px;padding-left:8px;@include border-radius(2px);@include transform(translateX(-100%));margin-top:9px;margin-left:20px;
			}
			.childButton{margin:12px auto;color:#fff;background:#f69f21;@include border-radius(50%);font-size: 16px;height:40px;width: 40px;min-width: 40px;display:block;padding:6px 10px;@include box-shadow-alpha(0, 2px, 8px, 2px, rgba(0,0,0,0.2));box-sizing: border-box;cursor: pointer;
				&.call{background:#4DB6AC;}
				&.task{background:#EEB54C;}
				&.meeting{background:#7986CB;}
				&.note{background:#66bb6a;}
				.callIcon, .noteIcon, .taskIcon, .meetingIcon, .emailIcon, .contactIcon{width:24px;height:24px;display:block;}
				.callIcon{background-position:-98px -48px;}
				.noteIcon{background-position:-121px -46px;}
				.taskIcon{background-position:-146px -48px;}
				.meetingIcon{background-position:-74px -47px;}
				.emailIcon{background-position:-169px -71px;}
				.contactIcon{background-position:-193px -70px;}
			}
		}
	}
	&.active{
		.mainButton span{
			transform:rotate(317deg);
		}
		.childrens{display:block;}
	}
}


.leadPage{background:#f8f8f8;width:100vw;height:100vh;}
.textContinue{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.navigation{width:100%;height:100px;background: #ffffff;@include box-shadow(-3px, 0px, 5px,rgba(0,0,0,0.2));position:relative;border-bottom:1px solid hsla(0, 0%, 0%, 0.15);box-sizing:border-box;position: fixed;
  &.loggedUser{height:60px;
    .logo{margin:0 18px;}
  }
  .logo{background: url('../images/logo.png') no-repeat;width:128px;height:64px;margin:18px;position: relative;display: inline-block;vertical-align: top;}
  .menu{display:inline-block;vertical-align:top;
    .menuBar{display:inline-block;vertical-align:top;padding:14px;
      .menuBarIcon{font-size:28px;color:#f69f21;cursor: pointer;}
    }
    .menuTitle{display:inline-block;vertical-align:top;padding:20px 10px;font-size:16px;}
  }
}
.steps{display:inline-block;vertical-align:top;margin:18px;position:relative;margin-top:40px;width:calc(100% - 400px);text-align:center;
  .step{color:#afbdc4;margin-left:25px;display:inline-block;vertical-align:top;font-weight:600;
    .icon{display:inline-block;vertical-align:top;margin-right:5px;@include border-radius(100%);border:1px solid #afbdc4;width:18px;height:18px;text-align:center;box-sizing: border-box;line-height: 13px;
      i{font-size:12px;display:none;margin-top:2px;color:#fff;}
      span{font-size:10px;}
    }
    .text{display:inline-block;vertical-align:top;font-size:14px;margin-top:1px;}
    &.isActive, &.completed{color:#f69f21;
      .icon{border-color:#f69f21;}
    }
    &.completed{
      .icon{background:#f69f21;
        i{display:block;}
      }
      span{display:none;}
    }
  }
}
.container{background: #e7eef0;width: 100%;height: calc(100vh - 102px);position: relative;display: block;top:100px;
  &.loggedUser{height: calc(100vh - 62px);top: 60px;}
}
.fullPagePopup{position:fixed;width:100vw;height:100vh;top:0;left:0;margin:0;background:#ebeef0;z-index: 2000;
	.fixedContainer{max-width:1000px;width:calc(100% - 250px);margin:0 auto;height:100%;
		@include respond-to(desktop) {max-width:100%;width:calc(100% - 36px);}
	}
	.header{background:#fff;width:100vw;height:60px;box-shadow: 0 5px 5px -5px rgba(0,0,0,.15);position: absolute;z-index: 10;
		.text{display:inline-block;vertical-align:top;font-size:20px;margin:18px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-weight: 500;}
		.buttons{display:inline-block;vertical-align:top;float:right;text-align: right;margin:12px;
			button{font-family:'Maven Pro';text-transform:capitalize;font-weight:400;font-size:16px;line-height:16px;padding:10px 30px;@include box-shadow-alpha(0px, 0px, 1px, 2px, rgba(0,0,0,0.03));@include border-radius(3px);
				&:hover{@include box-shadow-alpha(0px, 0px, 6px, 2px, rgba(0,0,0,0.07));}
			}
			.cancel{color:#778f9b;background:#fff;}
			.submit{background:#f69f21;color:#fff;margin-left:20px;border-color: #f69f21;}
		}
	}
	.body{background:#f9fafb;height:calc(100% - 60px);overflow-y:auto;display:table;padding-left:15px;width:calc(100vw - 15px);margin-top:60px;@include box-shadow(0px, 0, 0, rgba(0,0,0,.15), 'inset');@include css3-prefix('height', calc(100% - 60px));
		section{background:#fff;margin:15px 0 5px;border:1px solid #e2eaed;padding:15px;@include box-shadow-alpha(0px, 0px, 5px, -1px, rgba(0, 0, 0, 0.07));@include border-radius(3px);
			.headText{font-weight:500;font-size:16px;padding:10px 5px 20px;}
		}
		.w50{width:calc(50% - 1px);
      &.first{width:calc(50% - 15px);margin-right:15px;}
		}
		.w20{width:110px;}
		.w80{width:calc(100% - 110px);}
	}
}
div[class^="MuiModal-root"], div[role="dialog"], div[role="presentation"]{z-index: 5000;}
.w50{width:calc(50% - 1px);
	&.first{width:calc(50% - 15px);margin-right:15px;}
}
.w20{width:110px;}
.w80{width:calc(100% - 110px);}


.autoCompleteLabel{background: #fff;color: rgba(0, 0, 0, 0.54) !important;position: absolute;z-index: 300;font-size: 12px;padding: 0 4px;font-family: 'Roboto';line-height: 1;margin-left: 7px;margin-top: -6px;}
.autoComplete{
	.autoComplete__control{min-height:56px !important;
		&:hover{border-color:rgba(0, 0, 0, 0.23) !important;}
	}
	.autoComplete__indicator-separator{background-color: hsl(0,0%,80%);width:0px;}
	.autoComplete__dropdown-indicator{padding:4px;}
	.autoComplete__menu{z-index:3000;margin-top:5px;}
	.autoComplete__option:hover{background-color:rgba(246, 159, 33, 0.5);}

	&.hasError{
		.autoComplete__control{border-color:#de3226;}
	}
}

.emailVerify{width: 100%;height: 100%;text-align: center;padding-top: 20%;box-sizing: border-box;
	.message{font-size: 28px;margin-bottom: 20px;}
	.login{font-size:14px;}
}

.MuiPickersToolbar-toolbar,.MuiPickerDTTabs-tabs,.MuiPickersDay-isSelected,.MuiPickersClock-pin, .MuiPickersClockPointer-pointer,.MuiPickersClockPointer-thumb{background-color: #f69f21 !important;}
.MuiPickersClockPointer-thumb{border-color: #f69f21 !important;}
.MuiPickersDay-current:not(.MuiPickersDay-isSelected), .MuiPickersModal-dialogActions .MuiButton-textPrimary, .MuiDialogActions-root .MuiButton-textPrimary{color: #f69f21 !important;}
