$fontCardRaduis: 3px;

$primaryBackgroundColor: #93C74B;
$pictureBackgroundColor: #CED7DB;

$textOnPrimaryBg: #fff;

$primaryColor: #93C74B;

$textOnPrimaryColor: #fff;

$inputBackground: #fff;
$inputBorder: #CED7DB;

$border-radius-small: 3px;

$border-radius-meduim: 5px;

$idenediBgColor : #205f85;

$idenediColor : #fff;

$fullPagePopupLeftSideWidth: 340px;


/*** Opportunity  ***/

$prospectColor:   #7885CA;
$qualifyColor:    #4EC2F5;
$developColor:    #FBB150;
$solutionColor:   #75C279;
$proofColor:      #FF8A65;
$closeColor:      #00ACC1;
$deployColor:     #f4b252;
$wonColor:        #9F82D1;
$lostColor:       #f5564a;

/*** Opportunity  ***/


// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md-min:              $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);




$mq-desktop-lg:                           $screen-lg-desktop; // Usually 1200px
$mq-desktop:                              $screen-desktop;    // Usually 992px
$mq-tablet:                               $screen-sm-min;     // Usually 768px
$mq-mobile:                               $screen-phone;      // Usually 480px

// Inverse version media queries (for use with max-width)
// Some components needs to be strictly defined only for mobile

$mq-up-to-desktop-lg:                           ($screen-lg-desktop - 1); // Usually 1199px
$mq-up-to-desktop:                              ($screen-desktop - 1);    // Usually 991px
$mq-up-to-tablet:                               ($screen-sm-min - 1);     // Usually 767px
$mq-up-to-mobile:                               ($screen-phone - 1);      // Usually 479px






@mixin respond-to($media) {
  @if $media == mobile {
    @media (max-width: $mq-mobile) { @content; }
  }
  @else if $media == tablet {
    @media (max-width: $mq-tablet) { @content; }
  }
  @else if $media == desktop {
    @media (max-width: $mq-desktop) { @content; }
  }
  @else if $media == wide-screens {
    @media (min-width: $mq-desktop-lg) { @content; }
  }
  @else{
    @media ($media) { @content; }
  } 
}