.spriteIcon{background: url('../images/icons240.png') no-repeat;}
.irregularIcon{background: url('../images/icon-irregular.png') no-repeat;}
.filesIcon{background: url('../images/icons-files.png') no-repeat;}


.infoGreyIcon{background-position:-96px -72px;width:24px;height:24px;}
.dateGreyIcon{background-position:-48px -72px;width:24px;height:24px;}
.timeGreyIcon{background-position:-24px -72px;width:24px;height:24px;}
.userGreyIcon{background-position:-72px -72px;width:24px;height:24px;}
.locationGreyIcon{background-position:-144px -72px;width:24px;height:24px;}
.outgoingGreyIcon{background-position:0px -72px;width:24px;height:24px;}
.incomingGreyIcon{background-position:-120px -72px;width:24px;height:24px;}
.incentiveIcon{background-position:-99px -24px;width:18px;height:18px;}
.fileIcon{background-position:-168px -48px;width:24px;height:24px;}
.downloadOutlineIcon{background-position:-192px -48px;width:24px;height:24px;}
.watchIcon{background-position:-216px -48px;width:24px;height:24px;}


.docIcon{background-position:0px 0px;width:64px;height:64px;}
.videoIcon{background-position:0px -62px;width:64px;height:64px;}
.pdfIcon{background-position:0px -128px;width:64px;height:64px;}
.noFileIcon{background-position:0px -192px;width:64px;height:64px;}
.imageFileIcon{background-position:0px -256px;width:64px;height:64px;}



